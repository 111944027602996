@media only screen and (min-width: 992px) {}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .contact-mobile-spacing {
        padding-top: 2rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .contact-mobile-spacing {
        padding-top: 2rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .contact-mobile-spacing {
        padding-top: 0;
    }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.contact-info-line-height {
    line-height: normal !important;
}

.contact-mobile-spacing {
    padding-bottom: 1.9rem;
}