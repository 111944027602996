/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
    .our-products-container-border {
        border-left: 1px solid black;
    }
}

@media only screen and (min-width: 600) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    text-decoration: line-through
}

.hide {
    display: none !important;
    border: 1px solid green;
}

.our-methods-text {
    line-height: 30px;
}

.our-products-text {
    line-height: 30px
}

.card-body-container-height {
    height: auto !important;
}

.cleaning-products-img {
    -webkit-box-shadow: 8px 8px 15px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 8px 8px 15px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 8px 8px 15px -1px rgba(0, 0, 0, 0.75);
}