.navbar-brand-size {
    font-family: Oswald;
    font-size: 1.575rem !important;
    color: #fff !important;
}

.navbar-links-container {
    font-family: Oswald;
    font-size: 1.575rem;
    color: #fff !important;
}

.nav-link {
    color:#fff !important
}

.nav-container {
    background-color:#073b4c !important;
}

.custom-toggler.navbar-toggler {
    border-color: #E7A05F;
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(231,160,95, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}