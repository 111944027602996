@media only screen and (min-width: 992px) {
    .services-card-img {
        width: 14rem;
        rotate: 90;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .services-card {
        margin-top: 3rem;
    }

    .services-card-img {
        width: 15rem;
    }

    .card-container-py {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .services-card {
        margin-top: 3rem;
    }

    .services-card-img {
        width: 25rem;
    }

    .card-container-py {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .services-card-img {
        width: 14rem;
    }

    .services-card-text-mt {
        margin-top: 2.1rem !important;
    }

    .services-card-text-pb {
        padding-bottom: 1.8rem;
    }

    .card-container-py {
        padding-top: 0;
        padding-bottom: 0;
    }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .services-card-img {
        width: 14rem;
    }

    .services-card-text-mt {
        margin-top: 2.1rem !important;
    }

    .services-card-text-pb {
        padding-bottom: 0;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .services-card-img {
        width: 15rem;
    }

    .value-card-img {
        width: 10rem;
    }

    .services-card-text-mt {
        margin-top: 0 !important;
    }
}

.banner-background-img {
    margin: 0;
    padding: 0;

}

.banner-background-dark-layer {
    background-color: rgba(7, 59, 74, 0.55);
}

.banner-header-container {}

.banner-header-text {
    font-family: raleway;
    color: white;
}

.banner-header-button {
    padding-top: 0;
    padding-bottom: 0;
    font-family: Oswald;
    box-shadow: 2px 34px 69px 10px rgba(0, 0, 0, 0.75);

}


.services-card-img-radious {
    border-radius: 5px
}

.services-card-img-shadow {
    -webkit-box-shadow: 8px 8px 15px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 8px 8px 15px -1px rgba(0, 0, 0, 0.75);
    box-shadow: 8px 8px 15px -1px rgba(0, 0, 0, 0.75);
}

.services-card-title {
    font-family: Raleway;
    padding-top: 46px;
    padding-bottom: 13px;
    margin: 0
}

.services-card-text {
    margin: 0;
    margin-bottom: 1.7rem;

}

.home-headers-font-weigth {
    font-weight: 700;
}