.room-services-card-title-container {
    background-color: #073b4c;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: white;
}

.room-services-card-body-container {
    background-color: #118ab2;
    border-top-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white;
    height: 525px;
}